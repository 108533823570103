import React from "react"
import Layout from "../components/layout"
import { Row, Col } from "reactstrap"

const FormspreeContact = () => {
  return (
    <Layout navlocation="">
      <Row className="bg-dark my-3">
        <Col
          md={{ offset: 2, size: 8 }}
          className="py-3 text-white contact__form"
        >
          <h3>Activate Formspree</h3>

          <form action="https://formspree.io/f/mnqobobz" method="POST">
            <p>
              <label>
                <span>Your name:</span>
                <input type="text" required name="name" placeholder="NAME" />
              </label>
            </p>
            <p>
              <label>
                <span>Your email:</span>
                <input
                  type="email"
                  required
                  name="_replyto"
                  placeholder="EMAIL"
                />
              </label>
            </p>
            <p>
              <label>
                <span>Your phone number:</span>
                <input type="text" name="phone" placeholder="PHONE NUMBER" />
              </label>
            </p>
            <p>
              <label>
                <span>Message:</span>
                <textarea name="message" placeholder="MESSAGE" rows="6" />
              </label>
            </p>
            <p>
              <button className="btn btn-outline-info" type="submit">
                SUBMIT
              </button>
            </p>
          </form>
        </Col>
      </Row>
    </Layout>
  )
}

export default FormspreeContact
